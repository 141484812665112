import React from "react";
import "../App.css";
import illustration from "../img/undraw_under_construction_46pa.svg";

function UnderContstruction() {
  return (
    <div className="container">
      <img src={illustration} alt="under construction" />
      <h1>Andrew Nudelman</h1>
      <h2>UX Designer</h2>
      <p>site coming soon...</p>
    </div>
  );
}

export default UnderContstruction;
